import {DirectiveBinding} from 'vue'

// Your tracking function.
const trackClick = (event: Event) => {
  // Access _paq from the global window object.
  const _paq: any = window._paq || []
  _paq.push(['trackContentInteractionNode', event.target, 'click'])
}

const trackContentDirective = {
  // Called when the element is mounted into the DOM.
  beforeMount(el: HTMLElement, binding: DirectiveBinding) {
    if (typeof window === 'undefined') {
      return
    }
    // Set default data attribute to mark this element as tracked.
    el.setAttribute('data-track-content', '')

    // If an options object is provided, set additional data attributes.
    // Expected options: { link: string, name: string, contentName?: string }
    if (binding.value && typeof binding.value === 'object') {
      const {target, piece, name} = binding.value

      // ignore automatic interaction, we do it manually
      el.setAttribute('data-content-ignoreinteraction', target ?? '')

      el.setAttribute('data-content-target', target ?? '')
      if (piece) {
        el.setAttribute('data-content-piece', piece)
      }
      if (name) {
        el.setAttribute('data-content-name', name)
      }
    }

    // Add the click event listener in the capture phase
    // so that it fires before bubble-phase listeners (like @click).
    el.addEventListener('click', trackClick, true)
  },
  // Cleanup when the element is unmounted.
  unmounted(el: HTMLElement) {
    if (typeof window !== 'undefined') {
      el.removeEventListener('click', trackClick, true)
    }
  },
  getSSRProps() {
    return {}
  }
}

export default trackContentDirective
